<template>
    <b-modal ref="modal-client-department-profile-blocked-change" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('cp-change-exclusion',192)">
        
        <ValidationObserver ref="form" >
            <app-row-left-label :label="trans('date-to',182)">
                <app-date v-model="cArgs.date_to" :disabledDatesPass="1" :disabledDatesFuture="365" :clearable="true" />
            </app-row-left-label>

            <app-row-left-label :label="trans('description',182)">
                <app-memo v-model="cArgs.description" :maxLength="500" :lettersCounter="true" />
            </app-row-left-label>
        </ValidationObserver>
        <template #modal-footer>
            <app-button @click="closeModal" type="cancel">{{trans('cancel',175)}}</app-button>
            <app-button @click="change" type="primary" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>

    </b-modal>
</template>
<script>

import axios from "@axios";
import moment from "moment";
import { date_block_past } from "@validations";
import { ValidationObserver } from "vee-validate";

export default {

    components: {
        ValidationObserver
    },

    props: ["result", "args", "show", "return"],

    created(){

    },

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show',value) }
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

         cReturn: {
             get() { return this.return },
             set(value) { this.$emit('update:return', value) }
         }
    },

    data(){
        return {
            loading: false,
        }
    },  

    methods: {
        change(){
            this.$refs.form.validate().then((result) => {
                if (!result) {            
                    return false;
                }

            this.loading = true;

            axios
                .post('clients/location/profile/blocked-person/changeBlockedPerson', {
                    filters: JSON.stringify(this.cArgs.filters),
                    id_location_data_blocked: this.cArgs.id_location_data_blocked,
                    id_location_data_main: this.cArgs.id_location_data_main,
                    id_client_data_main: this.cArgs.id_client_data_main,
                    date_to: this.cArgs.date_to,
                    description: this.cArgs.description
                })
                .then((res) => {
                    this.cReturn.blocked_person.items = res.data.items;
                    this.$emit('update:result', 1);
                })
                .finally(() => {
                    this.cArgs.filters.search = '';
                    this.computedShow = false;
                });
            });

        },

        closeModal(){
            this.computedShow = false;
        }
    }

    
}
</script>
<style scoped>

.input-group-text {
    border: none;
}

.app-local-list-group-custom-style{
    overflow-y: auto;
}

.app-local-list-group-item-custom-style{
    cursor:pointer; 
    padding:4px 4px;
}

</style>