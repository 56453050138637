<template> 
    <b-modal ref="modal-change" size="md" dialog-class="app-local-max-width" v-model="computedShow" no-close-on-backdrop :title="trans('cp-change-schedule',192)">
        
        <ValidationObserver ref="form" >

            <app-row-left-label :label="trans('offer-via',182)">
                <app-select v-model="cArgs.registration_tool" :options="registration_tool_options" reduceValue :clearable="true" />
            </app-row-left-label>

            <app-row-left-label :label="trans('registraion',5)">
                <app-select v-model="cArgs.registration_process" :options="registration_process_options"   reduceValue :clearable="cArgs.registration_tool < 1" :disable="cArgs.registration_tool == 1"/>
            </app-row-left-label>

            <app-row-left-label :label="trans('client-confirmed-fw',182)">
                <app-select v-model="cArgs.client_confirmation" :options="client_confirmation_options" reduceValue :clearable="cArgs.registration_tool < 1" :disable="cArgs.registration_tool == 1"/>
            </app-row-left-label>

            <app-row-left-label :label="trans('send-offer-email',182)">
                <app-select v-model="cArgs.send_notification" :options="send_notification_options" reduceValue :clearable="cArgs.registration_tool < 1" :disable="cArgs.registration_tool == 1"/>
            </app-row-left-label>


        </ValidationObserver> 
        
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
    
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";


export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "result", "return","args"],

    watch: {
        "cArgs.registration_tool": {
            handler: function(val) {
                if (val > 0) {
                    
                    if(this.cArgs.registration_tool == 1){

                        this.cArgs.registration_process = 1;
                        this.cArgs.client_confirmation = 1;
                        this.cArgs.send_notification = 2;

                    }

                }                
            },
        },
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }, 
        },
         cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }, 
        },
    },

    data() {
        return {
            loading: false,
            registration_process_options: [
                { value: 0, name: this.trans('putting-it-through-directly',190) },
                { value: 1, name: this.trans('collect-data',3) }
            ],
            registration_tool_options: [
                { value: 0, name: this.trans('cp-mail-hn-portal',192)},
                { value: 1, name: this.trans('cp-customer-planning-system',192) }
            ],
            client_confirmation_options: [
                { value: 1, name: this.trans('yes-option',3) },
                { value: 0, name: this.trans('no-option',3) },
            ],
            send_notification_options: [
                { value: 0, name: this.trans('directly',3) },
                { value: 1, name: this.trans('put-in-queue',180) },
                { value: 2, name: this.trans('no-option',3)}
            ],
            import_procedure_options: [
                { value: 0, name: this.trans('cp-normal',192) },
                { value: 1, name: this.trans('cp-individual',192) }
            ]

        };
    },

    methods: {

        change() {
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{
                    
                    var planning_parameters = JSON.stringify({
                        registration_process: this.cArgs.registration_process,
                        registration_tool: this.cArgs.registration_tool,
                        client_confirmation: this.cArgs.client_confirmation,
                        send_notification: this.cArgs.send_notification
                    });

                    this.loading = true;

                    axios
                        .post('/clients/location/profile/planning/change', {
                            id_location_data_main: this.cArgs.id_location_data_main,
                            planning_parameters: planning_parameters
                        })
                        .then((res) => {
                            this.cReturn.location_data = res.data;
                            this.$emit('update:result', 1);
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading = false;
                            this.computedShow = false;
                        })

                }
            });
                
        },

        closeModal() {
            this.computedShow = false;
        },

        
    },
};
</script>
<style>
.app-local-max-width{
    max-width: 550px;
}
</style>

