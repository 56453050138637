<template>
    <div>
        <b-row class="app-component-icon-edit">
            <b-dropdown variant="link"  no-caret right v-if="userRole < 3 || profile.can_user_edit">
                <template #button-content>
                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                </template>
                <b-dropdown-item @click="change()">{{trans('change-location',3)}}</b-dropdown-item>
                <b-dropdown-item @click="changeCAO()" v-if="this.userRole == 1 || this.userRole == 2">{{trans('settings-change-cao',167)}}</b-dropdown-item>
            </b-dropdown>
        </b-row>

        <b-row class="app-card-header">
            <b-col start>
                <p class="col-list-label">{{trans('cp-use-organization-address',192)}}</p>
                <p class="col-list-label">{{trans('street',182)}}</p>
                <p class="col-list-label">{{trans('house-number',182)}}</p>
                <p class="col-list-label">{{trans('cw-house-number-addition',194)}}</p>
                <p class="col-list-label">{{trans('postcode',182)}}</p>
                <p class="col-list-label mb-3">{{trans('town',192)}}</p>
                <p class="col-list-label">{{trans('email',182)}}</p>
                <p class="col-list-label mb-3">{{trans('phone-number',182)}}</p>
                <p class="col-list-label ">{{trans('cp-cost-center',192)}}</p>
                <p class="col-list-label ">{{trans('cao',182)}}</p>
            </b-col>
            <b-col>
                <p class="col-form-value">{{profile.location_data.organization_address == 1 ? trans('yes-option',3) : trans('no-option',3)}}</p>
                <p class="col-form-value">{{profile.location_data.street  ? profile.location_data.street : '-'}}</p>
                <p class="col-form-value">{{profile.location_data.house_number ? profile.location_data.house_number : '-'}}</p>
                <p class="col-form-value">{{profile.location_data.number_additional ? profile.location_data.number_additional : '-'}}</p>
                <p class="col-form-value">{{profile.location_data.post_code ? profile.location_data.post_code : '-'}}</p>
                <p class="col-form-value mb-3">{{profile.location_data.city ? profile.location_data.city : '-'}}</p>

                 <p class="col-form-value">
                    <b-link v-if="profile.location_data.email" :href="'mailto:' + profile.location_data.email">{{profile.location_data.email}}</b-link>
                    <span v-if="!profile.location_data.email">-</span>
                </p>
                <p class="col-form-value mb-3">
                    <b-link v-if="profile.location_data.phone_number" :href="'tel:' + phone">{{phone}}</b-link>
                    <span v-if="!profile.location_data.phone_number">-</span>
                </p>
                <p class="col-form-value">{{profile.location_data.costplace_number ? profile.location_data.costplace_number : '-'}}</p>
                <p class="col-form-value">{{profile.location_data.section_name ? profile.location_data.section_name : '-'}}</p>
               
            </b-col>
        </b-row>

        <b-row class="mt-3" v-if="isAddress && !isCoordinates">
            <b-col cols="12">
                <b-alert variant="warning" show class="mb-0 d-flex align-items-center justify-content-between">
                    <div class="font-weight-600 text-base">{{trans('fp-address-coordinates-missing', 172)}}</div>
                    <app-button type="change" :loading="loading" @click="getCoordinates">{{trans('download', 175)}}</app-button>
                </b-alert> 
            </b-col>
        </b-row>

        <clients-profile-location-change v-if="mLocationsChange.show" :show.sync="mLocationsChange.show" :result.sync="mLocationsChange.result" :args.sync="mLocationsChange.args" :return.sync="profile"/>
        <clients-profile-cao-change :show.sync="mCaoChange.show" :args="mCaoChange.args" :result="mCaoChange.result" :return.sync="profile.location_data" />
    </div>
</template>
<script>

import ClientsProfileLocationChange from './ClientsProfileLocationChange.vue';
import ClientsProfileCaoChange from '@app/Clients/Profile/CAO/ClientsProfileCaoChange.vue';
import axios from "@axios";

export default {
    components: {
        ClientsProfileLocationChange,
        ClientsProfileCaoChange,
    },

    props:["profile"],

    created(){
        this.userRole = JSON.parse(localStorage.getItem('user')).id_user_const_role;
    },

    computed: {
        phone: function () {
            if (this.profile.location_data.phone_number !== null && this.profile.location_data.phone_prefix == '+31') {
                return 0 + this.profile.location_data.phone_number.replace(/^(.{1})(.{3})(.{3})(.*)$/, "$1 $2 $3 $4");
            } else {
                return this.profile.location_data.phone_prefix + " " + this.profile.location_data.phone_number;
            }
        },

        isAddress: {
            get() {
                return this.profile.location_data.post_code != null 
                    && this.profile.location_data.house_number != null 
                    && this.profile.location_data.street != null 
                    && this.profile.location_data.city != null
                    && this.profile.location_data.post_code != '' 
                    && this.profile.location_data.house_number != '' 
                    && this.profile.location_data.street != '' 
                    && this.profile.location_data.city != '';
            }
        },

        isCoordinates: {
            get() {
                return this.profile.location_data.latitude != null
                    && this.profile.location_data.longitude != null
                    && this.profile.location_data.latitude != ''
                    && this.profile.location_data.longitude != '';
            }
        },

        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value)}
        }
    },

    data(){
        return{
            userRole:null,
            mLocationsChange: { show: false, 
                                          args: { 
                                               location_data:{
                                                    id_location_data_main: null,
                                                    name: null,
                                                    location_short_name: null,
                                                    costplace_number: null,
                                                    organization_address: null,
                                                    address:{
                                                        street: null,
                                                        city: null,
                                                        house_number: null,
                                                        house_number_additional: null,
                                                        post_code: null,
                                                    },
                                                    address_data_main: null,
                                                    latitude: null,
                                                    longitude: null,
                                                    email:null,
                                                    phone_prefix: null,
                                                    phone_number: null,
                                                    list_type: null,
                                                },
                                                id_client_data_main: null

            }, result: 0, return: null },
            mCaoChange: { show: false, 
                                          args: { 
                                            profile_id: null,
                                            list_type:null,
                                            section: null,
            }, result: 0, return: null },
            loading: false,
        }
    },

    methods:{       
        change(){
            this.mLocationsChange.args.location_data.id_location_data_main = this.profile.id;
            this.mLocationsChange.args.location_data.name = this.profile.location_data.location_name;
            this.mLocationsChange.args.location_data.location_short_name = this.profile.location_data.location_short_name;
            this.mLocationsChange.args.location_data.costplace_number = this.profile.location_data.costplace_number;
            this.mLocationsChange.args.location_data.organization_address = this.profile.location_data.organization_address;
            this.mLocationsChange.args.location_data.address.street = this.profile.location_data.street;
            this.mLocationsChange.args.location_data.address.city = this.profile.location_data.city;
            this.mLocationsChange.args.location_data.address.house_number = this.profile.location_data.house_number;
            this.mLocationsChange.args.location_data.address.house_number_additional = this.profile.location_data.number_additional;
            this.mLocationsChange.args.location_data.address.post_code = this.profile.location_data.post_code;
            this.mLocationsChange.args.location_data.address_data_main = this.profile.location_data.address_data_main;
            this.mLocationsChange.args.id_client_data_main = this.profile.location_data.id_client_data_main;
            this.mLocationsChange.args.location_data.email = this.profile.location_data.email;
            this.mLocationsChange.args.location_data.phone_prefix = this.profile.location_data.phone_prefix? this.profile.location_data.phone_prefix : '+31';
            this.mLocationsChange.args.location_data.phone_number= this.profile.location_data.phone_number;
            this.mLocationsChange.args.location_data.address.latitude = this.profile.location_data.latitude;
            this.mLocationsChange.args.location_data.address.longitude = this.profile.location_data.longitude;
            //this.mLocationsChange.args.location_data.section.name = this.profile.location_data.section_name;
           // this.mLocationsChange.args.location_data.section.value = this.profile.location_data.id_cao_data_main;
            this.mLocationsChange.args.list_type= this.profile.type;
            this.mLocationsChange.args.filters = this.filters;
            this.mLocationsChange.show = true;
        },

        changeCAO(){
            this.mCaoChange.args.profile_id = this.profile.id;
            this.mCaoChange.args.section = this.profile.location_data.section_name != null ? {name: this.profile.location_data.section_name, value: this.profile.location_data.id_cao_data_main} : null;
            this.mCaoChange.args.list_type= this.profile.type;
            this.mCaoChange.show = true;
        },

        getCoordinates(){
            this.loading = true;
            axios
                .post("core/coordinates/downloadCoordinatesClient", {
                    id: this.profile.location_data.id,
                    type: 2
                })
                .then((res) => {
                    this.cProfile.location_data = res.data;
                    this.loading = false;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                });
        },
    }
 
};
</script>

<style scoped>

</style>