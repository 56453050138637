<template>
    <div>
        <b-row class="app-component-icon-edit">
            <b-dropdown variant="link"  no-caret right v-if="user_role < 3 || profile.can_user_edit">
                <template #button-content>
                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                </template>
                <b-dropdown-item @click="change()">{{trans('change',175)}}</b-dropdown-item>
            </b-dropdown>
        </b-row>      
            <b-row class="app-card-header">
                <b-col start>
                    <p class="col-list-label">{{trans('branch',182)}}</p>
                    <p class="col-list-label">{{trans('contactperson',169)}}</p>
                </b-col>
                <b-col>
                    <p class="col-form-value">{{profile.location_data.registration_office_name ? profile.location_data.registration_office_name : '-'}}</p>
                    <p class="col-form-value">{{profile.location_data.user_contact ? profile.location_data.user_contact : '-'}}</p>
                </b-col>
            </b-row>      

        <clients-profile-location-registration-change v-if="mRegistrationChange.show" :show.sync="mRegistrationChange.show" :result.sync="mRegistrationChange.result" :args.sync="mRegistrationChange.args" :return.sync="profile"/>
    </div>
</template>
<script>

import ClientsProfileLocationRegistrationChange from "./ClientsProfileLocationRegistrationChange.vue";


export default {

    components: {
        ClientsProfileLocationRegistrationChange
    },

    props:["profile"],

    data(){
        return{
            mRegistrationChange: { show: false, args: {
                id_location_data_main:null,
                contact_person: null,
                location:null,
                only_branch: true,
            }, result: 0, return: null },
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role, 
        }
    },

    methods:{
       
        change(){
            this.mRegistrationChange.args.id_location_data_main = this.profile.id;
            this.mRegistrationChange.args.contact_person = {name: this.profile.location_data.user_contact, value: this.profile.location_data.id_user_contact};
            this.mRegistrationChange.args.location = this.profile.location_data.id_registration_office_data_location ? {name: this.profile.location_data.registration_office_name, value: this.profile.location_data.id_registration_office_data_location, users: this.profile.location_data.location_users, student_recruitment: this.profile.location_data.student_recruitment} : null;
            this.mRegistrationChange.show = true;
        }

    }
 
};
</script>

<style scoped>

</style>