<template> 
    <b-modal ref="modal-client-profile-structure-location-change" size="l" v-model="computedShow" no-close-on-backdrop :title="trans('cp-change-department-information',192)">
        
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">
                <b-row>
                    <b-col>
                        <app-row-left-label :label="trans('full-name',3)">
                            <app-input v-model="cArgs.department_data.name" validatorName="Naam" validatorRules="required|max:40" mask="lettersE6-40"/>
                        </app-row-left-label> 

                        <app-row-left-label :label="trans('aliases',182)">
                                <app-tags v-model="cArgs.department_data.name_alias" :placeholder="trans('cp-enter-new-alias',192)" />
                        </app-row-left-label>

                        <app-row-left-label :label="trans('cp-external-id',192)">
                            <app-input v-model="cArgs.department_data.external_id" />
                        </app-row-left-label>

                        <app-row-left-label :label="trans('short-name',182)">
                            <app-input v-model="cArgs.department_data.department_short_name" validatorName="Korte naam" validatorRules="max:15" mask="lettersE6-15"/>
                        </app-row-left-label>   

                        <app-row-left-label :label="trans('cp-cost-center',192)">
                            <app-input v-model="cArgs.department_data.costplace_number" validatorName="Kostenplaats" mask="lettersE6-20"/>
                        </app-row-left-label>    
                        
                        <app-divider-modal :text="trans('contact-details',182)" :isLine="true"/>
                        
                        <app-row-left-label :label="trans('phone-number',182)">
                            <app-phone :prefix.sync="cArgs.department_data.phone_prefix" :phone.sync="cArgs.department_data.phone_number" :isRequired="false"/>
                        </app-row-left-label>

                        <app-row-left-label :label="trans('email',182)">
                            <app-email :email.sync="cArgs.department_data.email" validatorName="Email" type="person" />
                        </app-row-left-label>

                    </b-col>
                </b-row>
            </b-form>
        </ValidationObserver> 
        
        <template #modal-footer>
            <app-button @click="closeModal" type="cancel">{{trans('cancel',175)}}</app-button>
            <app-button @click="locationChange" type="primary" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
    
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import AppPhone from '@core/components/AppPhone.vue';
import AppEmail from '@core/components/AppEmail.vue';
import AppTags from '@core/components/AppTags.vue';

export default {
    components: {
        ValidationObserver,
        AppPhone,
        AppEmail,
        AppTags
    },

    props: ["show", "result", "args", "return"],

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },

        cReturn: {
            get() { return this.return },
            set(value) {this.$emit('update:return', value) }, 
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
    },

    data() {
        return {
            loading: false,
            tag_name:null,
        };
    },

    methods: {

        async locationChange() {
            this.$refs.form.validate().then((result) => {
                if (!result) {            
                    return false;
                }
                    
                this.loading = true;

                 axios
                    .post("clients/profile/location/department/change", {
                        id_client_data_main: this.cArgs.id_client_data_main,
                        id_location_data_main: this.args.id_location_data_main,
                        department_data: JSON.stringify(this.cArgs.department_data),
                        return_type: this.args.return_type,
                        filters: JSON.stringify(this.args.filters),
                    })
                    .then((res) => {
                        this.$emit("update:return", res.data);
                        this.$emit("update:result", 1);
                        this.$emit("update:show", false);                        
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading = false;
                    }); 
    
            })
                
        },

        tagValidator(tag) {
            if(tag.length > 60){
                return false;
            }else{
                return true;
            }
        },

        closeModal() {
            this.$emit("update:result", -1);
            this.$emit("update:show", false);
        },

    },
};
</script>
<style scoped>
</style>

