<template>
    <div>
        <b-row v-if="(user_role < 3 || profile.can_user_edit)" class="app-component-icon-edit">
            <b-dropdown variant="link"  no-caret right>
                <template #button-content>
                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                </template>          
                <b-dropdown-item @click="changePlanning()">{{trans('cp-change-of-schedule',189)}}</b-dropdown-item>
            </b-dropdown>
        </b-row>

        <b-row class="app-card-header mb-4">
            <b-col>
                <p class="col-list-label">{{trans('offer-via',182)}}</p>
                <p class="col-list-label">{{trans('registraion',5)}}</p>
                <p class="col-list-label">{{trans('client-confirmed-fw',182)}}</p>
                <p class="col-list-label">{{trans('send-offer-email',182)}}</p>
            </b-col>
           
            <b-col>
                <p class="col-form-value">{{planning_parameters.registration_tool == null ? '-' : planning_parameters.registration_tool == 1 ? trans('cp-customer-planning-system',192) : trans('cp-mail-hn-portal',192)}}</p>
                <p class="col-form-value">{{planning_parameters.registration_process == null ? '-' : planning_parameters.registration_process == 1? trans('collect-data',3) : trans('putting-it-through-directly',190)}}</p>
                <p class="col-form-value">{{planning_parameters.client_confirmation == null ? '-' : planning_parameters.client_confirmation == 1 ? trans('yes-option',3) : trans('no-option',3)}}</p>
                <p class="col-form-value">{{planning_parameters.send_notification == null ? '-' : planning_parameters.send_notification == 0 ? trans('directly',3) : planning_parameters.send_notification == 1 ? trans('put-in-queue',180) : trans('no-option',3)}}</p>
            </b-col>
        </b-row>

        <clients-profile-location-planning-change v-if="mChange.show" :show.sync="mChange.show" :args.sync="mChange.args" :result.sync="mChange.result" :return.sync="profile" />
    </div>
</template>
<script>

import ClientsProfileLocationPlanningChange from './ClientsProfileLocationPlanningChange.vue';

export default {
    components: {
        ClientsProfileLocationPlanningChange,

    },

    props:["profile"],

    computed: {
        cProfile: {
            get() { return this.profile },
            set(value) {this.$emit('update:profile', value) }
        }

    },

    created(){
        this.user_role = JSON.parse(localStorage.getItem('user')).id_user_const_role;
        this.parseData();
    },
    
    watch: {
        "mChange.result": {
            handler: function(val){
                if(val > 0){
                    this.parseData();
                    this.mChange.result = 0;
                }
            }
        },
    },

    data(){
        return{
                user_role:null,
                planning_parameters: null,
                mChange: { show: false, args: {
                                                id_location_data_main: null,
                                                registration_process: null,
                                                registration_tool: null,
                                                client_confirmation: null,
                                                send_notification: null,
                                               }, 
                            result: 0, return: null },
        }
    },


    methods:{
        parseData(){
            this.planning_parameters = JSON.parse(this.profile.location_data.planning_parameters);
        },
        
        changePlanning(){
            this.mChange.args.id_location_data_main = this.profile.id;
            this.mChange.args.registration_process = this.planning_parameters.registration_process;
            this.mChange.args.registration_tool = this.planning_parameters.registration_tool;
            this.mChange.args.client_confirmation = this.planning_parameters.client_confirmation;
            this.mChange.args.send_notification = this.planning_parameters.send_notification;
            this.mChange.show = true;

        },
            
    }
    
 
};
</script>
