<template>
    <div>
    <b-card no-body class="app-card-profile">
        <b-row class="app-card-header mb-1">
            <b-col start>
                <div class="app-card-profile-title">
                    {{trans('contacts',168)}}
                </div>
            </b-col>

            <b-col cols="auto" end class="d-flex align-items-center">
                <div style="min-width:24px; max-width:24px; min-height: 27px">  
                    
                    <b-link v-if="!loading_list" style="color: #98A2B3; cursor:pointer; outline:none !important;" @click="getList">
                        <b-icon icon="arrow-repeat" style="width:22px; height:22px" />
                    </b-link>

                    <b-spinner v-else style="width:19px; height:19px; margin-left:3px; margin-top:5px" small></b-spinner>       
                </div>
            </b-col>
        </b-row>

        <b-row class="ml-1 mr-1 pb-2 app-local-row-header-font" align-v="center" align-h="between" v-if="cProfile.contact.list.length > 0">
            <b-col cols="1" class='app-local-avatar'>
            </b-col>
            <b-col class="col-md-3 col-lg-4 col-xl-5">            
            </b-col>
            <b-col cols="1" class="app-width-min-40 app-local-header-padding pr-1">
                <span v-overflow-tooltip  v-b-tooltip.hover.top :title="this.trans('cp-planning',192)">P</span>
            </b-col>
            <b-col cols="1" class="app-width-min-40 app-local-header-padding pr-1">
                <span v-overflow-tooltip  v-b-tooltip.hover.top :title="this.trans('sh-hours',215)">U</span>
            </b-col>
            <b-col cols="1" class="app-width-min-40 pl-3 pr-1">
                <span v-overflow-tooltip v-b-tooltip.hover.top :title="this.trans('placment',5)">PL</span>
            </b-col>
            <b-col cols="1" class="app-width-min-40 pl-3 pr-1">
            </b-col>
        </b-row>

        <b-list-group flush class="app-local-bottom-line" v-if="cProfile.contact.list.length > 0">
            <b-list-group-item class="p-2 ml-2 mr-2"  v-for="item in cProfile.contact.list" :key="item.id_contact_data_main">
                <b-row align-v="center" align-h="between">
                    <b-col cols="1" class="app-local-avatar">
                        <clients-profile-contacts-avatar :contact="item" />
                    </b-col>
                    <b-col class="col-md-3 col-lg-4 col-xl-5">
                        <span v-overflow-tooltip class="app-local-row-font"> {{item.first_name}} {{item.inbetween_name}} {{item.last_name}}</span>
                        <div class="app-action-status-gray-text" v-if="item.source == 1">{{trans('cp-contact-person-client',168)}}</div>
                    </b-col>
                    <b-col  cols="1" class="app-width-min-40">
                        <app-check-box v-model="item.planning" class="custom-disabled" :disabled="true"/>
                    </b-col>
                    <b-col  cols="1" class="app-width-min-40">
                        <app-check-box v-model="item.hours" class="custom-disabled" :disabled="true"/>
                    </b-col>
                    <b-col  cols="1" class="app-width-min-40">
                        <app-check-box v-model="item.placement" class="custom-disabled" :disabled="true"/>
                    </b-col>
                    <b-col cols="1" class="app-width-min-40">
                        <b-dropdown variant="link" no-caret right v-if="item.source == 0">
                            <template #button-content>
                                <b-icon icon="three-dots-vertical" class="text-body" ></b-icon>
                            </template>
                            <b-dropdown-item @click="remove(item)">{{trans('remove',175)}}</b-dropdown-item>
                        </b-dropdown> 
                    </b-col>
                </b-row>
            </b-list-group-item>
        </b-list-group>

        <app-divider-modal :isLine="true" class="px-2"/>

        <b-row class="mt-2">
            <b-col><app-button type="secondary" @click="editContacts()">{{trans('cp-maintain-contacts',192)}}</app-button></b-col>
        </b-row>                             
    </b-card>
    
    <clients-profile-location-contacts :args="mClientsProfileLocationContacts.args" :return.sync="cProfile.contact.list" :show.sync="mClientsProfileLocationContacts.show" :result.sync="mClientsProfileLocationContacts.result" />

    </div>
</template>

<script>
import ClientsProfileContactsAvatar from './ClientsProfileContactsAvatar.vue';
import ClientsProfileLocationContacts from './ClientsProfileLocationContacts.vue';
import AppAlerts from '@core/scripts/AppAlerts';
import axios from "@axios";

export default {
    props: ["profile"],

    components: {
        ClientsProfileContactsAvatar,
        ClientsProfileLocationContacts
    },

    created() {
        this.onCreateThis();
    },

    computed: {       
        cProfile: {
            get() { return this.profile },
            set() { this.$emit('update:profile', false) }, 
        },

        cIdsContactDataMain: {
            get() { return this.profile.contact.list.filter(contact=> contact.source == 0).map(contact => contact.id_contact_data_main.toString()); }
        },
    },

    data() {
        return {
            alertClass: new AppAlerts(),
            loading_list: false,

            mClientsProfileLocationContacts: { args: {
                id_client_data_main: this.profile.location_data.id_client_data_main,
                id_location_data_main: this.profile.id,
                ids_contact_data_main: null,
                return_type: "location_profile",
            }, show: false, return: null, result: 0 } ,
        }
    },

    methods: {

        getList(){
            this.loading_list = true;

            axios
                .get("clients/location/getProfileLocationList/" + this.profile.location_data.id + '/' + this.profile.location_data.id_client_data_main)
                .then((res) => {
                    this.cProfile.contact.list = res.data;
                    this.loading_list = false; 
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                });

        },
        
        remove(item){
            this.loading_list = true;

            var name = item.first_name + " " + item.inbetween_name + " " + item.last_name
            this.alertClass.openAlertConfirmation(this.trans('unlink-contact-from-location-confirmation',191,{name:name})).then(res => {
                if(res == true) {                        
                    axios
                        .post("clients/profile/contact/removeFromLocation", {
                            id_contact_data_main: item.id_contact_data_main,
                            id_location_data_main: this.profile.location_data.id,
                            id_client_data_main: this.profile.location_data.id_client_data_main,
                            return_type: "location_profile"
                        })
                        .then((res) => {
                            this.cProfile.contact.list = res.data;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading_list = false;
                        });
                    } else {
                        this.loading_list = false;
                    }
                });
        },

        onCreateThis(){
            this.$watch('profile.contact.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

        },

        contactAllClose(event){
            if(event == 1){
                this.getList();
            }
        },

        editContacts(){
            this.mClientsProfileLocationContacts.show = true;
            this.mClientsProfileLocationContacts.args.ids_contact_data_main =  JSON.parse(JSON.stringify(this.cIdsContactDataMain));
        },
    }
   
}
</script>

<style scoped>
.app-local-row-font {
    color: #174066;
    font-weight: 300;
    font-size: 14px;
}

.app-local-row-header-font {
    font-weight: 600;
    color: #1f627f;
    font-size: 14px;
    text-transform: none;
    letter-spacing: 1px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
}

.checkbox-green .custom-control-input:checked ~ .custom-control-label::before{
    background-color: #12B76A !important;
}

.app-local-header-padding{
    padding-left: 20px;
}

.app-action-status-gray-text{
    font-size: 12px;
    color: #8898aa;
}

@media (max-width: 1460px){
    .app-local-avatar {
        display: none;
    }
}


</style>
