<template>
    <b-modal ref="client-profile-contacts" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('cp-location-contacts',192)"> 
        <clients-profile-contacts-component listType="location" :idsContactDataMain.sync="args.ids_contact_data_main" :idClientDataMain="args.id_client_data_main" :itemsCount.sync="items_count"/>
        <template #modal-footer>
            <app-button @click="closeModal" type="cancel">{{trans('cancel',175)}}</app-button>
            <app-button @click="save" :loading="loading" type="primary" :disabled="items_count == 0" >
                {{trans('save',175)}}
            </app-button>
        </template>

    </b-modal>
</template>

<script>

import axios from "@axios";
import ClientsProfileContactsComponent from '@app/Clients/Profile/Contact/ClientsProfileContactsComponent.vue';

export default {
    props: ["show", "result", "args", "return"],

    components:{
        ClientsProfileContactsComponent
    },

    data(){
        return{
            loading: false,
            id_client_data_main: this.args.id_client_data_main,
            items_count: 0
        }
    },

    created() {
        
    },

    computed:{
        computedShow:{
            get() { return this.show },
            set() { this.$emit('update:show', false) },
        },
    },

    methods: {

        closeModal() {
            this.computedShow = false;
            this.$emit("update:result", -1);
        },

        save() {         
            this.loading = true;
            axios
                .post("clients/profile/contact/changeInLocation", {
                    id_client_data_main: this.id_client_data_main,
                    id_location_data_main: this.args.id_location_data_main,
                    ids_contact_data_main: JSON.stringify(this.args.ids_contact_data_main),
                    return_type: this.args.return_type,
                    filters: JSON.stringify(this.args.filters),
                })
                .then((res) => {
                    this.$emit("update:return", res.data);
                    this.$emit("update:result", 1);
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                    
                    this.$emit("update:show", false);
                });
        },
    }

}
</script>

<style scoped>
.card-body {
    padding-top: 0.5rem;
}

</style>