<template> 
    <ValidationObserver ref="observer">
        <app-row-left-label :slots="[4,4,4]" :label="trans('address-details',3)" :customError="true">
            <template v-slot:col-1>
                <app-input id="vi-post-code" :currentError.sync ="cCurrentErrorPostCode" v-model="cAddress.post_code" :placeholder="trans('postcode',182)" mask="####AA" :disabled="disabled || loading_disabled " v-on:blur="checkAddress()" :uppercase="true" />
            </template>
            <template v-slot:col-2>
                <app-input id="vi-house-number" :currentError.sync ="cCurrentErrorHouseNumber" v-model="cAddress.house_number" :key="refresh" :placeholder="trans('house-number',182)" :disabled="disabled || loading_disabled" v-on:blur="checkAddress()" type="integer_05" />
            </template>
            <template v-slot:col-3>
                <app-input id="vi-number-additional" :currentError.sync ="cCurrentErrorNumberAdditional" v-model="cAddress.house_number_additional" :placeholder="trans('addition',182)" :disabled="disabled || loading_disabled" v-on:blur="checkAddress()" mask="lettersE3-5"/>
            </template>
            <template v-slot:custom-error>
                <app-input type="hidden" ref="customErrorInput" :validatorId="'hidenAddressError'+_uid.toString()"  v-model="checked_address_key" name="validator-address" :validatorRules="cAddressRequired" :validatorCustomMessage="{ required: cErrorMessage }"/>
            </template>                            
        </app-row-left-label>


        <app-row-left-label label=" " v-if="loading || status == 1" >            
            
            <b-card no-body class="p-2 app-local-card-min-height app-card-info">
                <div class="app-info-card-label" v-if="loading">
                    <b-spinner class="app-local-spinner"/>{{trans('checking',180)}}
                </div>
                <div class="app-info-card-label" v-if="!loading && status === 1">
                    <label class="flabel">{{trans('found-address',3)}}</label>
                    <div> {{ checked_street_info }} </div>
                    <div> {{ checked_city_info }} </div>
                </div>
            </b-card>
        </app-row-left-label>
    </ValidationObserver>
</template>

<script>
import axios from "@axios";
import {ValidationObserver} from "vee-validate";

export default {
    components: {
        ValidationObserver
    },

    props: {
        address: {},
        required: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false }, 
           
    },


    watch: {
        "cAddress.post_code":{
            handler:async function(val) {
                if(this.status != 0){
                    this.status = 0;
                    this.checked_address_key = ""
                    this.checked_city_info = "";
                    this.checked_street_info = "";
                    this.$refs.observer.validate();
                    this.loading = true;
                    await this.$nextTick();
                    this.loading = false;
                }
                 this.loading = true;
                await this.$nextTick();
                this.loading = false;
                
            }
        },
        "cAddress.house_number":{
            handler:async function(val) {
                if(  this.status != 0){
                    this.status = 0;
                    this.checked_address_key = ""
                    this.checked_city_info = "";
                    this.checked_street_info = "";
                    this.$refs.observer.validate();
                    
                }
                this.loading = true;
                await this.$nextTick();
                this.loading = false;
            }
        },
        "cAddress.house_number_additional":{
            handler:async function(val) {
                if( this.status != 0){
                    this.status = 0;
                    this.checked_address_key = ""
                    this.checked_city_info = "";
                    this.checked_street_info = "";
                    this.$refs.observer.validate();
                }
                 this.loading = true;
                await this.$nextTick();
                this.loading = false;
                
            },
        }
    },
    
    created() {
        if(this.address.city != null && this.address.post_code != null &&  this.address.city.length > 0 && this.address.post_code.length > 0){
            if (this.address.house_number_additional !== null && this.address.house_number_additional.length > 0) {
                this.checked_address_key = this.address.post_code.toString() + this.address.house_number.toString() + this.address.house_number_additional.toString();
                this.checked_street_info = this.address.street.toString() + " " + this.address.house_number_additional.toString() + " " + this.address.house_number_additional.toString();                                   
            } else {
                this.checked_address_key = this.address.post_code.toString() + this.address.house_number.toString();
                this.checked_street_info = this.address.street.toString() + " " + this.address.house_number.toString();
            }

        
             this.checked_city_info = this.address.post_code.toString() + " " + this.address.city.toString()
        }

       

        if (this.checked_address_key != null && this.checked_address_key.length > 0) {
            this.status = 1;
        }
        this.cAddress.country = { value:149 }
    },

    mounted(){
        this.isMounted = true;
    },

    computed: {
        cAddress:{
            get() { return this.address },
            set(value) { this.$emit('update:address', value) }
        },

        cErrorMessage:{
            get() { 
                    if (this.loading) return " ";
                    else if (this.address.post_code.length == 0 && this.address.house_number.length == 0 && this.address.house_number_additional == null) return this.trans('address-required',3)
                    else if (this.address.post_code.length == 0) return this.trans('postcode-required',180)
                    else if (this.address.post_code.length < 6) return this.trans('invalid-postcode',180)
                    else if (this.address.house_number.length == 0) return this.trans('house-number-required',180)
                    else if (this.status == 2) return this.trans('invalid-address',180)
                    return " "
                }
        },

        cAddressRequired: {
            get() { 
                if (this.loading) return null;
                else if(this.required) return "required";
                else if(this.address.post_code.length > 0 || this.address.house_number.length > 0 || (this.address.house_number_additional !== null && this.address.house_number_additional.length > 0)) return "required";
                return null;
            }
        },

        cCurrentErrorPostCode: {
            get() {
                if(!this.isMounted) return false
                if(this.$refs.observer.errors['hidenAddressError'+this._uid.toString()] == null) return false
                if(this.$refs.observer.errors['hidenAddressError'+this._uid.toString()].length == 0) return false;
                
                if(this.$refs.observer.errors['hidenAddressError'+this._uid.toString()][0] == this.trans('postcode-required',180) )  return true;
                if(this.$refs.observer.errors['hidenAddressError'+this._uid.toString()][0] == this.trans('invalid-postcode',180) )  return true;
                if(this.$refs.observer.errors['hidenAddressError'+this._uid.toString()][0] == this.trans('invalid-address',180) )  return true;
                return false;
            }
        },

        cCurrentErrorHouseNumber: {
            get() {
                
                if(!this.isMounted) return false
                if(this.$refs.observer.errors['hidenAddressError'+this._uid.toString()] == null) return false
                if(this.$refs.observer.errors['hidenAddressError'+this._uid.toString()].length == 0) return false;
                if(this.$refs.observer.errors['hidenAddressError'+this._uid.toString()][0] == this.trans('house-number-required',180) )  return true;
                if(this.$refs.observer.errors['hidenAddressError'+this._uid.toString()][0] == this.trans('invalid-address',180) )  return true;
                
                return false;
            }
        },

        cCurrentErrorNumberAdditional: {
            get() {
                if(!this.isMounted) return false
                if(this.$refs.observer.errors['hidenAddressError'+this._uid.toString()] == null) return false
                if(this.$refs.observer.errors['hidenAddressError'+this._uid.toString()].length == 0) return false;
                if(this.$refs.observer.errors['hidenAddressError'+this._uid.toString()][0] == this.trans('invalid-address',180) && this.cAddress.house_number_additional.length > 0 )  return true;
                
                return false;
            }
        }
    },

    data() {
        return {
            loading: false,   
            checked_address_key : "",
            checked_street_info: "",
            checked_city_info: "",
            status: 0,
            refresh: 0,
            isMounted: false,
            loading_disabled: false  
        };
    },
    
    methods: {
        checkAddress() {
            this.refresh++;

            var address_key = "";
            if (this.address.house_number_additional != null) {
                address_key = this.address.post_code + this.address.house_number + this.address.house_number_additional;
            } else {
                address_key = this.address.post_code + this.address.house_number;
            }
            
            if (address_key != this.checked_address_key) {
                this.status = 0;
                this.checked_address_key = ""
                this.checked_city_info = "";
                this.checked_street_info = "";
            } 

            if (address_key == this.checked_address_key) {
                this.$refs.observer.validate();
                return;
            }

            if(this.address.post_code.length < 6 || this.address.house_number.length == 0) {
                this.$refs.observer.validate();
                return;
            }

            this.loading = true;
            this.loading_disabled = true;
            this.cAddress.city = "";
            this.cAddress.street = "";
            this.cAddress.latitude = null;
            this.cAddress.longitude = null;
            this.$refs.observer.validate();

            axios
                .post("core/post-nl/check", { 
                    post_code: this.address.post_code, 
                    house_number: this.address.house_number, 
                    number_additional: this.address.house_number_additional, 
                    })
                .then((res) => {
                    if(res.data == -1){
                        this.status = 2;

                    }else if (res.data === "" || res.data === undefined || res.data === null) {
                        this.status = 2;
                    } else {
                        if (res.data.number_additional !== null) {
                            this.checked_address_key = res.data.post_code.toString() + res.data.house_number.toString() + res.data.number_additional.toString();
                            this.checked_street_info = res.data.street.toString() + " " + res.data.house_number.toString() + " " + res.data.number_additional.toString();                                   
                        } else {
                            this.checked_address_key = res.data.post_code.toString() + res.data.house_number.toString();
                            this.checked_street_info = res.data.street.toString() + " " + res.data.house_number.toString();
                        }

                        this.checked_city_info = res.data.post_code.toString() + " " + res.data.city.toString()
                        
                        this.cAddress.city = res.data.city;
                        this.cAddress.street = res.data.street;
                        this.cAddress.latitude = res.data.latitude;
                        this.cAddress.longitude = res.data.longitude;
                        
                        this.status = 1;
                    }
   
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                    this.loading_disabled = false;
                }); 
        },
    },
};

</script>

<style scoped>
.app-local-card-min-height {
    min-height:58px;
}

.app-local-spinner {
    width:16px; 
    height:16px; 
    margin-right:10px;
    font-size: 11px;
}
</style>
