<template>
    
    <b-card class="mb-3">
        <app-view-table :title="trans('departments',3)" :isTitleTotal="true" :loading="loading_list" :filters.sync="filters" :items="cProfile.structure_departments.items" :fields="fields" :customColumns="customColumns"  :isMenuColumn="user_role < 3 || profile.can_user_edit" :filterOptions="status_list" :isFilterOptions="true">
        
            <template v-slot:menu-items="cell">
                <b-dropdown-item @click="changeDepartment(cell.data.item)">{{trans('change',175)}}</b-dropdown-item>
                <b-dropdown-item @click="removeDepartment(cell.data.item)"  v-if="cell.data.item.deleted != 1">{{trans('remove',175)}}</b-dropdown-item>
            </template>
            
           <template v-slot:info="cell">
                <div class="app-local-title"> <b-link @click="getProfile(cell.data.item)"> {{cell.data.item.name}} </b-link></div>
                <div class="app-local-details mr-3"> {{ getAddress(cell.data.item) }} </div>
            </template>

            <template v-slot:contacts="cell">
                <div class="d-flex" @drop="onDrop($event, cell.data.item.ids_contact_data_main, cell.data.item)" @dragover.prevent @dragenter.prevent>
                    <clients-profile-contacts-avatar-component :contacts="cProfile.structure_departments.contacts" :ids_contact_data_main="cell.data.item.ids_contact_data_main" @onRemove="removeDepartmentContact($event, cell.data.item)"/> 
                </div>
            </template>
            
            <template v-slot:buttons>
                <app-button @click="addDepartment()" type="primary" v-if="user_role < 3 || profile.can_user_edit">{{trans('cp-add-department',192)}}</app-button>
            </template>
        </app-view-table>
        
        <clients-profile-department-add-with-contacts v-if="mDepartmentAdd.show" :show.sync="mDepartmentAdd.show" :result.sync="mDepartmentAdd.result" :args.sync="mDepartmentAdd.args" :return.sync="cProfile.structure_departments"/>
        <clients-profile-department-change-with-contacts v-if="mDepartmentChange.show" :show.sync="mDepartmentChange.show" :result.sync="mDepartmentChange.result" :args.sync="mDepartmentChange.args" :return.sync="cProfile.structure_departments"/>
    </b-card>

</template>

<script>
import axios from "@axios";
import ClientsProfileContactsAvatarComponent from '@app/Clients/Profile/Contact/ClientsProfileContactsAvatarComponent.vue';
import ClientsProfileDepartmentAddWithContacts from '@app/Clients/Profile/Department/ClientsProfileDepartmentAddWithContacts.vue';
import ClientsProfileDepartmentChangeWithContacts from '@app/Clients/Profile/Department/ClientsProfileDepartmentChangeWithContacts.vue';

import AppAlerts from '@core/scripts/AppAlerts';

export default {
    components: {
        ClientsProfileContactsAvatarComponent,
        ClientsProfileDepartmentAddWithContacts,
        ClientsProfileDepartmentChangeWithContacts
    },

    props: ["profile"],
    
    mounted() {
         this.onCreateThis();
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        },

    },

    data() {
        return {
            filters: {
                page: 1,
                per_page: 10,
                sorting: {
                    field_name: null,
                    sort_direction: 0,
                    changed: 0,
                },
                changed:0,
                filtering: {},
                search: "",
                status: 1,
                list_type:1,
            },
            status_list: [
                { value: 1, name: this.trans('active',3) },
                { value: 2, name: this.trans('blocked',3) },
                { value: 3, name: this.trans('cp-show-all',192) }               
            ],
            ids_location_data_expanded: [],
            alertClass: new AppAlerts(),         
            loading_list: false,
            
            mDepartmentAdd: { show: false, args: {id_location_data_main: null, contacts: null, filters:null, return_type:null}, result: 0, return: null },
            mDepartmentChange: { show: false, 
                               args: {id_location_data_main: null,
                                        id_location_data_main: null,
                                        filters:null, 
                                        return_type:null,
                                        contacts: null,
                                        
                                        department_data: {
                                                        id_department_data_main: null,
                                                        name: "",
                                                        department_short_name: "",
                                                        costplace_number: null,
                                                        name_alias:null,
                                                        email: '',
                                                        phone_prefix: '+31',
                                                        phone_number: '',
                                                        external_id: null,
                                                    }
                                    }, 
                               result: 0,
                               return: null },

            customColumns: ["info", "contacts"],

            fields: [
                { key: "info", label: "Afdelingen", thStyle: { "min-width": "200px", width: "100%" }, visible: true, filtering: false},
                { key: "contacts", label: "Contactpersonen", thStyle: { "min-width": "300px", "max-width": "300px" }, visible: true, filtering: false, sortable: false}
            ],   
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,         
            
        };
    },

     methods: {

        getList: function() {
            this.loading_list = true;
            axios
                .post("clients/profile/structure/getDepartments", {
                    id_location_data_main: this.profile.id,
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.cProfile.structure_departments = res.data;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_list = false;
                });
        }, 

        onDrop(event, ids_contact_data_main, item) {
            var item_id = event.dataTransfer.getData('id_contact_data_main')
            if(!ids_contact_data_main.includes(item_id)) {
                this.loading_list = true;
                
                axios
                .post("clients/profile/contact/addToDepartment", {
                    id_department_data_main: item.id_department_data_main,
                    id_contact_data_main: item_id,
                    id_location_data_main: this.cProfile.id,
                    filters: JSON.stringify(this.filters),  
                    return_type: "location_profile", 
                })
                .then((res) => {
                    this.cProfile.structure_departments = res.data;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
                 
            }            
        },

         getAddress(data) {
             if (data.post_code != undefined && data.post_code != null && data.post_code != "") {
                var l_address = data.street + ' ' + data.house_number;
                if (data.number_additional !== undefined && data.number_additional) {
                    l_address = l_address + " " + data.number_additional;
                }

                l_address = l_address + ", " + data.city;
                return l_address; 
             } else {
                 return "";
             }
            
         },
         
        onCreateThis() {  

            this.$watch('cProfile.structure_departments.items.refresh', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                   this.getList();
                }   
            });

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loadingList) {
                        this.getList();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.list_type', function(newVal, oldVal) {
                if (!this.loadingList) {
                    this.getList();
                } 
            });
        },

        addDepartment() {
            this.mDepartmentAdd.args.id_location_data_main = this.profile.id;
            this.mDepartmentAdd.args.id_client_data_main = this.profile.location_data.id_client_data_main;
            this.mDepartmentAdd.args.filters = this.filters;
            this.mDepartmentAdd.args.return_type = "location_profile";
            this.mDepartmentAdd.result = 0;
            this.mDepartmentAdd.show = true;
        },

        changeDepartment(item) {
            this.mDepartmentChange.args.id_location_data_main = this.profile.id;
            this.mDepartmentChange.args.id_client_data_main = this.profile.location_data.id_client_data_main;
            this.mDepartmentChange.args.filters = this.filters;
            this.mDepartmentChange.args.return_type = "location_profile";
            this.mDepartmentChange.args.ids_contact_data_main = item.ids_contact_data_main;
            this.mDepartmentChange.args.department_data.id_department_data_main = item.id_department_data_main;
            this.mDepartmentChange.args.department_data.name = item.name;
            this.mDepartmentChange.args.department_data.department_short_name = item.department_short_name;
            this.mDepartmentChange.args.department_data.costplace_number = item.costplace_number;
            this.mDepartmentChange.args.department_data.email = item.email;
            this.mDepartmentChange.args.department_data.phone_prefix = item.phone_prefix == null ? '+31' : item.phone_prefix;
            this.mDepartmentChange.args.department_data.name_alias = JSON.parse(JSON.stringify(item.name_alias != null ? item.name_alias.split(',') : []));
            this.mDepartmentChange.args.department_data.phone_number = item.phone_number;
            this.mDepartmentChange.args.department_data.external_id = item.external_id;
            this.mDepartmentChange.result = 0;
            this.mDepartmentChange.show = true;
        },

        removeDepartment(item){

            this.alertClass.openAlertConfirmation("Weet je zeker dat je <b>" + item.name + "</b> wilt verwijderen afdeling?").then(res => {
                if(res == true) {
                    this.loading_list = true;
                    axios
                        .post("clients/profile/location/department/remove", {
                            id_department_data_main: item.id_department_data_main,
                            id_location_data_main: item.id_location_data_main,
                            id_location_data_main: this.cProfile.id,
                            filters: JSON.stringify(this.filters),  
                            return_type: "location_profile", 
                        })
                        .then((res) => {
                            this.cProfile.structure_departments = res.data;
                            this.filters.page = res.data.page;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading_list = false;
                        });
                }
            });
        },

        removeDepartmentContact(event, item) {
            var name = event.first_name + " " + event.last_name
            this.alertClass.openAlertConfirmation("Weet je zeker dat je <b>" + name + "</b> wilt loskoppelen van deze afdeling?").then(res => {
                if(res == true) {  
                    this.loading_list = true;                      
                    axios
                        .post("clients/profile/contact/removeFromDepartment", {
                            id_contact_data_main: event.id_contact_data_main,
                            id_department_data_main: item.id_department_data_main,
                            id_location_data_main: this.cProfile.id,
                            id_client_data_main: this.profile.location_data.id_client_data_main,
                            filters: JSON.stringify(this.filters),  
                            return_type: "location_profile", 
                        })
                        .then((res) => {
                            this.cProfile.structure_departments = res.data;
                            this.filters.page = res.data.page;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading_list = false;
                        });
                }
            });
        },

        getProfile: function(item, type) {
            this.$router.push({ name: "clients-profile-department", params: { id_department_data_main: item.id_department_data_main } });
        },
     },

    
};
</script>

<style>
.app-local-details {
    font-size: 0.8125rem;
    line-height: 1;    
}

.app-local-title {
    font-size: 0.9rem;
    font-weight: 600;
}

.app-local-col-width {
    min-width: 200px;
    max-width: 200px; 
}


</style>
