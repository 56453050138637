<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('enrollment',3)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">
                <app-row-left-label :label="trans('branch',182)">
                    <app-select v-model="cArgs.location" type="getUserLocations" validatorRules="required" validatorName="Vestiging" />
                </app-row-left-label>

                <app-row-left-label :label="trans('contactperson',169)">
                    <app-select v-model="cArgs.contact_person" :refreshIndex.sync="refresh_users" :args.sync="users_args" type="getUsersFromLocation" :disable="users_args.id_office_data_location < 1" validatorRules="required" validatorName="Contactpersoon" /> 
                </app-row-left-label>

            </b-form>
        </ValidationObserver>

        <template #modal-footer>

                <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
                <app-button type="primary" @click="change" :loading="loading">
                    {{trans('save',175)}}
                </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver
    },

    props: ["show", "args", "return", "result"],

    created(){

    },

    watch: {
        "cArgs.location": {
            handler: function(val) {
                
                this.users_args.id_office_data_location = this.args.location.value;

                this.cArgs.contact_person.name = null;
                this.cArgs.contact_person.value = null;
                this.cArgs.contact_person = null;

                this.refresh_users++;
            },
            deep: true,
        }
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cReturn: {
            get() { return this.return },
            set() { this.$emit('update:return', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }, 
        },
    },


    data() {
        return {
            loading: false,
            refresh_users: 1,
            users_args: {id_office_data_location: this.args.location.value},
        };
    },

    methods: {
        change() {
   
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }
                else{
                    this.loading = true;
                    axios
                        .post("clients/location/profile/registration/change", {
                            id_location_data_main: this.args.id_location_data_main,
                            id_user_contact: this.cArgs.contact_person.value,
                            id_office_data_location: this.cArgs.location.value
                        })
                        .then((res) => {
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);
                            this.cReturn.location_data = res.data;
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        });
                }
            })
            
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style scoped>
</style>
