<template>
  <div v-if="profile !== null" class="app-navbar-profile-content">  
    <b-navbar sticky class="app-navbar-profile" style="top: 111px">
      <div class="app-navbar-header">
        <b-row>
          <b-col cols="8" class="app-navbar-profile-margin-4"></b-col>
          <b-col end class="app-navbar-profile-margin-30">
              <div class="d-flex justify-content-end align-items-center">
                <div class="font-weight-800 mr-4 mt-1"> {{trans('core-location-profile', 271)}} </div>
                <b-dropdown variant="link" right>
                    <template #button-content>
                        <b-icon icon="gear-fill" style="width: 17px; height: 17px;"></b-icon>
                    </template>
                    <b-dropdown-item :to="{ name: 'location-profile-history', params: { id_location_data_main: id_location_data_main }}">{{trans('history',182)}}</b-dropdown-item>
                </b-dropdown>
              </div>
            </b-col>
        </b-row>
         <b-row class="align-items-center mb-3">
            <b-col cols="8"> 
              <clients-profile-location-status-bar-component :profile.sync ="profile" />
            </b-col>
          </b-row> 
        </div>
    </b-navbar>
    <div>
          <b-row>
            <b-col md="12" lg="8" class="pr-lg-2">
                <clients-profile-location-general-data-component class="mb-3" :profile.sync="profile" />

                <!--<b-tabs class="mb-3" justified v-on:input="changeTab">
                  <b-tab title="" active><clients-profile-location-structure-component v-if="profile.structure_departments !== undefined" :profile.sync="profile"/></b-tab> 
                </b-tabs>-->
                <clients-profile-location-structure-component v-if="profile.structure_departments !== undefined" :profile.sync="profile"/>

                <app-collapse accordion type="margin">
                    <b-card no-body class="app-card-client">
                        <app-collapse-item :isVisible="true" style="box-shadow:none !important;" :title="trans('cp-location-data',190)" :id="'location-information'">
                            <clients-profile-location-component :profile.sync="profile"/>
                        </app-collapse-item>
                    </b-card>

                    <b-card no-body class="app-card-client">
                        <app-collapse-item :isVisible="true" style="box-shadow:none !important;" :title="trans('enrollment',3)" :id="'registration'">
                            <clients-profile-location-registration-component :profile.sync="profile"/>
                        </app-collapse-item>
                    </b-card>

                    <b-card no-body class="app-card-client">
                      <app-collapse-item  style="box-shadow:none !important;" :title="trans('cp-planning',192)" :id="'planning'">
                        <clients-profile-location-planning-component :profile.sync="profile" />
                      </app-collapse-item>
                    </b-card>

                    <b-card no-body class="app-card-client">
                      <app-collapse-item  style="box-shadow:none !important;" :title="trans('cp-excluded-flexworkers',192)" :id="'excluded-flexworkers'">
                        <clients-profile-location-blocked-component :profile.sync="profile" />
                      </app-collapse-item>
                    </b-card>

                </app-collapse>
            
              </b-col>

              <b-col md="12" lg="4" class="mt-3 mt-lg-0 pl-lg-2">
                <clients-profile-location-contacts-component class="mb-3" :profile.sync="profile"/>
                <issues-profile-component class="mb-3" :profile.sync="profile" type="location"/>
                <notes-component :profile.sync="profile"/>
              </b-col>
          </b-row>

        </div>
  </div>
</template>
<script>


import AppCollapse from "@core/components/AppCollapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/AppCollapse/AppCollapseItem.vue";
import axios from "@axios";
import ClientsProfileLocationStatusBarComponent from './Location/ClientsProfileLocationStatusBarComponent.vue';
import IssuesProfileComponent from '@app/Issues/IssuesProfileComponent.vue';
import NotesComponent from '@app/Notes/NotesComponent.vue';
import ClientsProfileLocationGeneralDataComponent from './Location/ClientsProfileLocationGeneralDataComponent.vue';
import ClientsProfileLocationContactsComponent from './Contact/ClientsProfileLocationContactsComponent.vue';
import ClientsProfileLocationComponent from './Location/ClientsProfileLocationComponent.vue';
import ClientsProfileLocationStructureComponent from './Location/ClientsProfileLocationStructureComponent.vue';
import ClientsProfileLocationRegistrationComponent from './Location/ClientsProfileLocationRegistrationComponent.vue';
import ClientsProfileLocationPlanningComponent from './Location/ClientsProfileLocationPlanningComponent.vue';

//Collapse
import ClientsProfileLocationBlockedComponent from './Location/ClientsProfileLocationBlockedComponent.vue'
//BTab

export default {
  components: {
    
    AppCollapse,
    AppCollapseItem,
    ClientsProfileLocationStatusBarComponent,
    ClientsProfileLocationGeneralDataComponent,
    ClientsProfileLocationContactsComponent,

    //Right sidebar
    IssuesProfileComponent,
    NotesComponent,
    ClientsProfileLocationComponent,

    //Tabs
    ClientsProfileLocationStructureComponent,
    //Collapse
    ClientsProfileLocationRegistrationComponent,
    ClientsProfileLocationPlanningComponent,
    ClientsProfileLocationBlockedComponent,
  },

  created() {
    this.getData();
  },

  data(){
      return {
      
              id_location_data_main: this.$route.params.id_location_data_main,
              profile: null,
              activeTab: 0,

          }

  },

  watch: {
      "profile.location_data_refresh": {
            handler: function(val) {
                if (val > 0) {
                    this.getPersonData();
                }                
            },
        },
  },

  methods: {
    
    getData(){
      axios
        .get("clients/location/getProfile/" + this.id_location_data_main)
        .then((res) => {
            this.profile = res.data;

            this.$root.$refs.AppNavigator.updatePath('/clients/organization/'+ this.profile.location_data.id_client_data_main, '');
        })
        .catch((error) => {
            console.error(`error during request: ${error}`);
        });
    },

    getPersonData(){
      
      axios
        .get("clients/location/getPersonData/" + this.id_location_data_main)
        .then((res) => {
            if(res.data.length > 0){
              this.profile.location_data = res.data[0];  
            } 
        })
        .catch((error) => {
            console.error(`error during request: ${error}`);
        });
    },

    changeTab(e){
      if (this.activeTab !== e) {
        this.activeTab = e;
       if(e == 0){//department
          this.profile.department.refresh ++;
        }else if(e == 1){//contact_person
          this.profile.contact_person.refresh ++;
        }
      }
      

    },

    //showLoading: function() {
    //  this.loading = !this.loading;
    //},

    /*scrollToDocument: function(val){
      
      this.openCollapseDocument = !this.openCollapseDocument;
      this.refreshPersonalDocumentsWithType = val;

      setTimeout(() => {
          
            document.getElementById('footer').scrollIntoView({
                                                                behavior: "smooth",
                                                                block: "start"
                                                            });

                    }, 800);

    }, */


  },

  mounted() {
    
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      
      if (collapseId == 'collapse-documents' && isJustShown == true){        
          //this.profile.documents.refresh ++;
      } else if(collapseId == 'collapse-competence' && isJustShown == true) {
       
      } else if(collapseId == 'collapse-excluded-flexworkers' && isJustShown == true) {
        this.profile.blocked_person.items.refresh++;
      }

    }); 

  }
 
};
</script>
<style>
  .bg-light{
    background-color: white !important;
  }
</style>